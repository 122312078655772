import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#E81616",
        secondary: "#5F5F5F",
        accent: "#07C730",
        error: "#E81616",
        info: "#E81616",
        success: "#5F5F5F",
        warning: "#2BA681",
        leftPanel: "#c2c9d6",
        rightPanel: "#ffffff",
        textColor: "#000000",
        textColorLeft: "#000000",
        spec: "#003895",
      },
      dark: {
        primary: "#E81616",
        secondary: "#5F5F5F",
        accent: "#07C730",
        error: "#E81616",
        info: "#E81616",
        success: "#5F5F5F",
        warning: "#2BA681",
        leftPanel: "#c2c9d6",
        rightPanel: "#000000",
        textColor: "#ffffff",
        textColorLeft: "#ffffff",
        spec: "#FFEA00",
      },
    },
  },
});
